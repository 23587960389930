
import ChatMessageImage from "./ChatMessageImage";
import moment from 'moment';
import {SERVER_URL} from '../../../Config/index';
import user from '../../../Public/Images/user.svg';
import {useEffect, useRef, useState } from "react";
import ChatMessageOptions from "./ChatMessageOptions";
import translate from '../../Translations/index';
import {useLongPress} from '../../../Library/hooks';
import Helper from "../../../Library/helper";

const ChatMessage = ({isVs,allMessages,setAllMessages,containerRef,scrollAfterUpdateMessages, item:{fromCustomer,image,width,height,message,dateAdd,toGroupID,readedInGroup,readed,customerChatMessagesID,deleted,deletedAtMe,deletedAtThey,deletedInGroupAtThey}}) => {

    var helper = new Helper();

    var newWidth = 0;
    var newWHeight = 0;

    const [messageText, SetMessageText] = useState(null);
    const [showOption, SetShowOption] = useState(false);
    const [position, SetPosition] = useState({
        x:0,
        y:0
    });

    const longPressPros = useLongPress((e) => {
        if(helper.IsiOS())
            OpenMessageSettings(e,false);
    },500,true);
    
    const OpenMessageSettings = (e,stopPropagation) => {
        
        if((!isVs && deletedAtMe == 0 && deleted == 0) || (isVs && !deletedAtThey))
        {  
            if(stopPropagation)
            {
                e.persist();
                e.preventDefault();
            }

            GetMessageText(e.target);

            var clientX = (e.touches ? e.touches[0].clientX : e.clientX);
            var clientY = (e.touches ? e.touches[0].clientY : e.clientY);
            
            var sideBarWidth = 250;
            var optionsH = 200;
            var optionsW = 200;

            if(window.innerWidth <= 1500)optionsH = 190;
            if(window.innerWidth <= 767)optionsH = 155;
            if(window.innerWidth <= 575)optionsH = 145;

            var posX = clientX - optionsW;
            if(window.innerWidth > 991)
            {
                if(posX - sideBarWidth < 0) posX = clientX;
            }
            else
            {
                if(posX < 0) posX = clientX;
            }

            var posY = clientY - optionsH;
            if(posY < 0) posY = clientY;

            SetPosition({
                x: posX,
                y: posY
            });
            SetShowOption(true);
        }
    }

    const GetMessageText = (target) => {
        
        const p = target.closest(".current-message-container").querySelector(".msg");
        if(p)
        {
            const message = target.closest(".current-message-container").querySelector(".msg").innerText;
            SetMessageText(message);
        }
    }
    
    if(image && containerRef && !(deleted || (!isVs && deletedAtMe) || (isVs && deletedAtThey) || deletedInGroupAtThey))
    {
        const containerWidth = containerRef.current.content.clientWidth;

        var aspectRatio = width / height;
        if(width < height)
        {
            newWHeight = 400;
            newWidth = Math.round(newWHeight * aspectRatio); 
        }
        else
        {
            newWidth = 400;
            if(containerWidth <= newWidth + 200)newWidth = containerWidth / 100 * 75;
            newWHeight = Math.round(newWidth / aspectRatio); 
        }
    }

    return(
        <>
            {isVs ?
                <div className="image">
                    <img src={(fromCustomer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + fromCustomer.photo : user)} />
                </div>
            :null}

            <div {...longPressPros} onContextMenu={(e) => {if(!helper.IsiOS())OpenMessageSettings(e,true)}} style={(newWidth != 0 ? {width:newWidth + "px"} : null)}  className={"current-message-container" + (newWidth == 0 ? " mw-75 " : "")  + (isVs && ((readed == 0 && toGroupID == 0) || (toGroupID != 0 && readedInGroup == 0)) ? " not-readed" : "")}>
                
                {isVs ?
                    <p className="name">{(fromCustomer.astralName != "" ? fromCustomer.astralName : fromCustomer.name)} </p>
                :null}

                {image && !(deleted || (!isVs && deletedAtMe) || (isVs && deletedAtThey) || deletedInGroupAtThey) ?
                    <ChatMessageImage name={image} width={newWidth} height={newWHeight} />
                :null}

                <div className="current-message-content">  
                    {deleted || (!isVs && deletedAtMe) || (isVs && deletedAtThey) || deletedInGroupAtThey ?
                        <p className={"remove-message"+(!isVs ? " my" : "")}>{translate.t("ThisMessageWasDeleted")}</p>
                    :

                        (message ?       
                            <p className={"msg" + (image ? " with-margin" : "")} dangerouslySetInnerHTML={{__html:message}}></p>
                        :null)
                    }
                    <div className={"current-message-date" + (image && !message ? " no-border" : "") + (!isVs ? " my" : "")}>{moment(dateAdd).format("D.M.YYYY HH:mm:ss")}</div>
                </div>
                
            </div>
            {!isVs ?
                <div className="image right">
                    <img src={(fromCustomer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + fromCustomer.photo : user)} />
                </div>
            :null}

            {showOption ?
                <ChatMessageOptions 
                    isVs={isVs} 
                    groupID={toGroupID} 
                    scrollAfterUpdateMessages={scrollAfterUpdateMessages} 
                    allMessages={allMessages} 
                    setAllMessages={setAllMessages} 
                    customerChatMessagesID = {customerChatMessagesID} 
                    showOption = {showOption} 
                    SetShowOption = {SetShowOption} 
                    position = {position}  
                    messageText = {messageText}
                />
            :null}
        </>
    )

}

export default ChatMessage;
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { UseTheme } from '../../../GlobalComponents/Theme';

// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.js';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';
// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content.js';
import 'tinymce/skins/ui/oxide/content.js';

const TinyMCEEditor = (props) => {

    const {theme} = UseTheme();
    var height = 1000;
    if(props.useWindowHeight)
    {
        height = window.innerHeight - 300;
        if(window.innerWidth < 576)
            height = window.innerHeight - 260;
    }

    var options = {

        plugins: 'link code emoticons lists',
        toolbar: 'undo redo blocks bold italic alignleft aligncenter alignright forecolor backcolor formatselect numlist bullist checklist link blockquote emoticons code',
        paste_as_text: true,
        language_url : '/langs/cs.js',
        language: "cs",
        body_class: 'tiny-body',
        height:height,
        link_assume_external_targets: true,
        license_key: 'gpl',
        promotion: false
    }

    if(theme == 2)
    {
        options.skin_url = "static/js/skins/ui/oxide-dark";
        options.content_css = [
            'dark',
            '/css/tinymce_dark.css?2'
        ];
    }

    return(

        <Editor
            name="description"
            value={props.initialValue}
            init={options}
            onEditorChange={(content) => props.onChange(content,"text")}
        />
        
       
    )  

}

export default TinyMCEEditor;  

import React,{ useEffect, useState } from 'react';
import {withApollo,useQuery,useMutation } from 'react-apollo';
import Modal from 'react-modal';
import translate from '../../Translations/index';
import {GET_CUSTOMER_MODAL_DATA,UPDATE_CUSTOMER_MODAL_DATA} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import AllCustomerDailyPosts from '../../Daily/Components/AllCustomerDailyPosts';
import Materials from './Materials';
import CustLib from '../Library/customer';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import Profile from './Profile';
import CustomerQuestionnaireSteps from './CustomerQuestionnaireSteps';
import InternatlNotes from './InternatlNotes';
import close from '../../../Public/Images/Icons/close.webp';

const CustomerModalAllDetails = (props) => {

    var custLib = new CustLib(props);

    const [selectedTab, setSelectedTab] = useState(1);
    const [formData, setFormData] = useState({
        selectedMaterials:[],
        customerID:0,
        description:""
    })

    const {data,loading,error} = useQuery(GET_CUSTOMER_MODAL_DATA,{
        fetchPolicy: 'network-only',
        variables:{
            customerID:props.customerID
        }
    });

    const [updateCustomerModalData,{error:errorUpdate,loading:loadingUpdate}] = useMutation(UPDATE_CUSTOMER_MODAL_DATA,{
        onCompleted: (data) => {

            if(data.updateCustomerModalData.includes("not-posible")){

                var days = data.updateCustomerModalData.split("-");
                days = days.pop();

                var notification = new Notification();
                notification.setNotification(null,translate.t("ChangeMaterialNotPosible") +" " + days + " " + translate.t("ChangeMaterialNotPosible2"),false,true,props.client);

            }else if(data.updateCustomerModalData == "no-member"){

                var notification = new Notification();
                notification.setNotification(null,translate.t("ChangeMaterialNoMember"),false,true,props.client);

            }else {
                props.saveModal();

                var notification = new Notification();
                notification.setNotification(null,translate.t("SucessfullySaved"),true,false,props.client);
            }
            
            
        }

    });

    const setSelectedMaterials = (selectedMaterials) => {

        setFormData({...formData,selectedMaterials:selectedMaterials});
    }

    const setDescritption = (desc) => {

        setFormData({...formData,description:desc});
    }

    useEffect(() => {

        if(data && data.oneCustomer){

            var selectedMaterials = [];
            if(data.oneCustomer.materials.length > 0){
                for(let val of data.oneCustomer.materials){
                    selectedMaterials.push(val.materialID);
                }
            }

            setFormData({...formData,selectedMaterials:selectedMaterials,customerID:props.customerID,description:data.oneCustomer.description});
        }

    },[data]);

    var err = "";
    if(error || errorUpdate){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || errorUpdate);  

    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-xl"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.closeModal()}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    {data && data.oneCustomer && (data.oneCustomer.astralName ? data.oneCustomer.astralName : data.oneCustomer.name + " " + data.oneCustomer.surname)}{data && data.oneCustomer && (data.oneCustomer.customerLastMaterial ? " - " + data.oneCustomer.customerLastMaterial.longName : "")}
                    <img src={close} className="close" onClick={() => props.closeModal()} />
                </div>
                <div className="modal-body">

                    {err != "" ?
                        <Error text={err} />  
                    :
                        <>
                            <ul className="nav nav-tabs with-border" role="tablist">
                                <li className="nav-item">
                                    <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#current-lesson" onClick={(e) => setSelectedTab(1)}>Aktuální lekce</a>
                                </li>
                                <li className="nav-item">
                                    <a className={(selectedTab == 2 ? "nav-link active" : 'nav-link')} href="#daily" onClick={(e) => setSelectedTab(2)}>Deník</a>
                                </li>
                                <li className="nav-item">
                                    <a className={(selectedTab == 3 ? "nav-link active" : 'nav-link')} href="#materials" onClick={(e) => setSelectedTab(3)}>Aktivní podklady</a>
                                </li>
                                <li className="nav-item">
                                    <a className={(selectedTab == 4 ? "nav-link active" : "nav-link")} href="#profile" onClick={(e) => setSelectedTab(4)}>Profil</a>
                                </li>
                                <li className="nav-item">
                                    <a className={(selectedTab == 5 ? "nav-link active" : "nav-link")} href="#profile" onClick={(e) => setSelectedTab(5)}>Dotazník</a>
                                </li>
                                <li className="nav-item">
                                    <a className={(selectedTab == 6 ? "nav-link active" : "nav-link")} href="#profile" onClick={(e) => setSelectedTab(6)}>Interní poznámka</a>
                                </li>

                            </ul>

                            <div className="tab-content" id="myTabContent">

                                {loading || loadingUpdate ?
                                    <Loading />
                                :
                            
                                    <>
                                        {selectedTab == 1 ?

                                            (data.oneCustomer.customerLastMaterial.description != "" ?
                                                <div className="tiny-content" dangerouslySetInnerHTML={{__html:data.oneCustomer.customerLastMaterial.description}}></div>
                                            :
                                                
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="alert alert-warning text-center no-margin">{translate.t("NoLessonText")}</div>
                                                    </div>
                                                </div>
                                            )
                                            

                                        : null}

                                        {selectedTab == 2 ?
                                            <AllCustomerDailyPosts isVSList = {false} materials = {data.oneCustomer.materials} lastMaterialID = {data.oneCustomer.customerLastMaterial.materialID} myMotivation = {data.oneCustomer.myMotivation} guideID = {data.customerContext.customerID} customerID = {data.oneCustomer.customerID} />
                                        :null}

                                        {selectedTab == 3 ?
                                            <Materials customer = {data.oneCustomer} selectedMaterials = {formData.selectedMaterials} setSelectedMaterials={setSelectedMaterials}  />
                                        :null}

                                        {selectedTab == 4 ?
                                            <Profile isVSList = {false} customer = {data.oneCustomer} />
                                        :null}

                                        {selectedTab == 5 ?
                                            <CustomerQuestionnaireSteps customer = {data.oneCustomer} />
                                        :null}

                                        {selectedTab == 6 ?
                                            <InternatlNotes customer = {data.oneCustomer} description = {formData.description} SetDescritption = {setDescritption} />
                                        :null}
                                        
                                    </>

                                }

                            </div>

                        </>

                    }

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => custLib.saveCustomerModalData(updateCustomerModalData,formData)}>{translate.t("Save")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.closeModal()}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default withApollo(CustomerModalAllDetails);
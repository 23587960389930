/*
    Formulář pro přihlášení se do adminu
*/
import React,{useState} from 'react';
import {useMutation,useQuery,withApollo} from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import {GET_EVENT_CATEGORIES,ADD_EVENT_CATEGORY_TO_NOT_SEND} from '../../EmailSmsNotification/Queries/emailSmsNotify';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import info from '../../../Public/Images/info.svg';
import Modal from 'react-modal';
import EmailSmsNotifyLib from '../../EmailSmsNotification/Library/emailSmsNotify';
import logo from '../../../Public/Images/logo.svg';
import close from '../../../Public/Images/Icons/close.webp';


const Unsubscribe = (props) => {

    localStorage.removeItem("token");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("membershipTo");

    const code   = props.match.params.code;

    var emailSmsNotifyLib = new EmailSmsNotifyLib(props);

    let variables = {
        lang:translate.locale,
        regCode:code
    }

    const [eventDetails, showEventDetails] = useState(null);
    const [selectedEventCategories, setSelectedCategories] = useState([]);
    const [e,setError] = useState(null);
    const [success,setSuccess] = useState("");

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_EVENT_CATEGORIES,{
        fetchPolicy: 'network-only',
        variables,
        onCompleted:(data) => {

            var arr = [];
            for(let val of data.allEventCategoryNotSend){
                arr.push(val.eventCategoryID);
            }

            

            setSelectedCategories(arr);
        }
    });

    const [addEventCategoryToNotSend,{error:addError,loading:AddLoading}] = useMutation(ADD_EVENT_CATEGORY_TO_NOT_SEND,{
        onCompleted: (data) => {

            if(data.addEventCategoryNotSends != ""){

                setError(translate.t("WrongCustomer"));

            }else{

                setSuccess(translate.t("SuccessfullyChanged"));

            }

        }

    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  

    }
    if(e)err = e;
		   		  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>
        	<div className="card">

	        	<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body">

                    {err ?
                        <Error text = {err} />
                    :
                        (loading ?
                            <Loading />
                        :
   
                            <form>

                                <p className="reg-title">
                                    <strong>Zvolte prosím kategorii z které se chcete odhlásit</strong>
                                </p>
                                            
                                
                                <div>

                                    <ul className="no-dots text-left">
                                        {data.allEventCategorys.map((item,index) => {

                                            let checked = false;
                                            for(let val of selectedEventCategories){
                                                if(val == item.eventCategoryID)checked = true;
                                            }

                                            return (
                                                <li className="mb-2" key={index}>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input value={item.eventCategoryID} checked={checked} type="checkbox" className="form-check-input" onChange={() => emailSmsNotifyLib.setEventCategory(item.eventCategoryID,selectedEventCategories, setSelectedCategories)} />
                                                            <label className="form-check-label d-flex align-items-center" >
                                                                <strong>{item.name}</strong> <img onClick={() => showEventDetails(item)} className="info-icon ml-2" src={info} title="Klikněte pro detailní popis" />
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </li>
                                            )
                                        })}
                                        
                                    </ul>
                                
                                    <button type="button" onClick={() => addEventCategoryToNotSend({
                                        variables:{
                                            eventCategories:selectedEventCategories,
                                            regCode: code
                                        }
                                    })} className="btn btn-primary btn-blue btn-block">{translate.t('Unsubscribe')}</button>

                                    {success ?
                                        <div className="alert alert-success mb-0 mt-3">{success}</div>
                                    :null}
                                        
                                    
                                </div>
                                
                                {error && <div className="alert alert-danger mt-4 mb-0">{error}</div>}

                                {eventDetails ?

                                    <Modal
                                        className="Modal__Bootstrap modal-dialog modal-lg"
                                        closeTimeoutMS={150}
                                        isOpen={true}
                                        onRequestClose={() => showEventDetails(null)}
                                    >
                                        <div className="modal-content">
                                            <div className="modal-header d-flex align-items-center">
                                                {eventDetails.name}
                                                <img src={close} className="close" onClick={() => showEventDetails(null)} />
                                            </div>
                                            <div className="modal-body event">

                                                <div className="tiny-content" dangerouslySetInnerHTML={{__html:eventDetails.text}}></div>

                                            </div>
                                        </div>
                                    </Modal>

                                :null}
                                

                            </form>
                        )
                
                    }
	        	</div>
        	</div>
        </div>
    );
  
}

export default withApollo(Unsubscribe);

import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
import {GET_CUSTOMER_DAILY_POST} from '../Queries/daily';

class Daily {

    constructor(props){
        this.props = props;
    }

    async getData(customerDailyPostID,formData,setFormData){

        var data = await this.props.client.query({ 
            query: GET_CUSTOMER_DAILY_POST,
            errorPolicy:"all",
            variables:{
                customerDailyPostID
            }, 
            fetchPolicy: 'network-only'
        });

        if(data.data){

            setFormData({...formData,
                text:data.data.customerDailyPost.text,
                customerDailyPostID:customerDailyPostID
            });
            
        }

    }

    deleteCustomerdailyPost(act,customerDailyPostID,deleteCustomerDailyPost,setShowDelete){
        
        if(act && customerDailyPostID){
            deleteCustomerDailyPost({
                variables:{
                    customerDailyPostID
                }
            })
        }
        setShowDelete(false);
    }

    addPost(formData,addCustomerDailyPost){

        if(formData.text != ""){

            
            formData.messageType = parseInt(formData.messageType);
            if(!formData.messageType)formData.messageType = 1;
            
            addCustomerDailyPost({
                variables:formData
            })

        }else{

            var notify = new Notification();
            notify.setNotification(null,translate.t("InvalidText"),false,true,this.props.client);
        }

    }

}

export default Daily;
import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
import {useMutation,withApollo} from 'react-apollo';
import TinyMCEEditor from '../../TinyMCE/Components/TinyMceEditor';
import translate from '../../Translations/index';
import DailyLib from '../Library/daily';
import {ADD_CUSTOMER_DAILY_POST,GET_CUSTOMER_DAILY_DATA} from '../Queries/daily';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import close from '../../../Public/Images/Icons/close.webp';

const AddPost = (props) => {

    var dailyLib = new DailyLib(props);

    const [formData,setFormData] = useState({
        text:                "",
        customerID:          props.customerID,
        guideID:             props.guideID,
        customerDailyPostID: props.selectedPostID,
        messageType:         props.messageType
    });

    const [addCustomerDailyPost,{error,loading}] = useMutation(ADD_CUSTOMER_DAILY_POST,{
        onCompleted: (data) => {

            if(props.selectedPostID && props.selectedPostID != 0){

                const { allCustomerDailyPosts } = props.client.readQuery({ query: GET_CUSTOMER_DAILY_DATA,variables: props.variables });

                var arr = [...allCustomerDailyPosts];
            
                arr.forEach((item,index) => {
                    
                    if(item.customerDailyPostID == data.addEditCustomerDailyPost.customerDailyPostID){
                        arr[index].text       = data.addEditCustomerDailyPost.text;
                    }
    
                });
                props.client.writeQuery({ 
                    query:GET_CUSTOMER_DAILY_DATA,
                    variables:props.variables,
                    data:{
                        allCustomerDailyPosts: arr
                    } 
                });

            }else{
                props.refetchPosts();
            }

            setFormData({...formData,text:""});
            
            CloseAddModal()
        }

    });

    useEffect(() =>{
    
        if(props.showAdd)
        {
            if(props.selectedPostID && props.selectedPostID != 0)
            {
                dailyLib.getData(props.selectedPostID,formData,setFormData);
            }else{

                if(props.selectedText != ""){
                    setFormData({...formData,customerDailyPostID:0,text: "<p>&nbsp;</p><blockquote>" + props.selectedText + "</blockquote>"})
                }
                else
                {
                    setFormData({...formData,customerDailyPostID:0})
                }
            }
        }
    },[props.selectedText,props.selectedPostID,props.showAdd]);

    const CloseAddModal = () => {
        
        if(props.selectedPostID != 0)
            setFormData({...formData,text:""})

        props.setShowAdd(false)
    } 
    
    var err = "";
    if(error){
        var helper = new Helper();
        err = helper.getApolloErrorText(error);  
    }

    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-xl"
            closeTimeoutMS={150}
            isOpen={props.showAdd}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    {(props.selectedPostID && props.selectedPostID != 0 ? translate.t("EditPost") :  translate.t("AddPost"))}
                    <img src={close} className="close" onClick={() => CloseAddModal()} />
                </div>
                <div className="modal-body">

                    {err ?

                        <Error text = {err} />
                
                        :
                        (loading ?
                            <Loading />
                            :
                            <>
                                {!(props.selectedPostID && props.selectedPostID != 0) ? 
                                    <div>
                                        <label className="input-label">{translate.t('MessageType')}</label>
                                        <div className="form-group">
                                            <select className="form-control" value = {formData.messageType} onChange={(e) => setFormData({...formData,messageType:e.target.value})}>
                                                <option value={1}>{translate.t("Messages")}</option>
                                                <option value={22}>{translate.t("MyProgress")}</option>
                                                {props.showTabsByMaterial.dreams ?
                                                    <option value={2}>{translate.t("Dreams")}</option>
                                                :null}
                                                {props.showTabsByMaterial.sync ?
                                                    <option value={3}>{translate.t("Sync")}</option>
                                                :null}
                                                {props.showTabsByMaterial.intangibleExperiences ?
                                                    <option value={4}>{translate.t("IntangibleExperiences")}</option>
                                                :null}
                                                {props.showTabsByMaterial.negativCharacteristics ?
                                                    <option value={5}>{translate.t("NegativCharacteristics")}</option>
                                                :null}
                                                {props.showTabsByMaterial.positiveCharacteristics ?
                                                    <option value={6}>{translate.t("PositiveCharacteristics")}</option>
                                                :null}
                                                {props.showTabsByMaterial.charismaticEssence ?
                                                    <option value={7}>{translate.t("CharismaticEssence")}</option>
                                                :null}
                                                {props.showTabsByMaterial.joys ?
                                                    <option value={8}>{translate.t("30Joys")}</option>
                                                :null}
                                                {props.showTabsByMaterial.transformation ?
                                                    <option value={19}>{translate.t("Transformation")}</option>
                                                :null}
                                                {props.showTabsByMaterial.lifeValues ?
                                                    <option value={9}>{translate.t("LifeValues")}</option>
                                                :null}
                                                {props.showTabsByMaterial.meaningOfLife ?
                                                    <option value={10}>{translate.t("meaningOfLife")}</option>
                                                :null}
                                                {props.showTabsByMaterial.wishes ?
                                                    <option value={11}>{translate.t("108wishes")}</option>
                                                :null}
                                                {props.showTabsByMaterial.dreamEightPlusOne ?
                                                    <option value={12}>{translate.t("8+1dream")}</option>
                                                :null}
                                                {props.showTabsByMaterial.actor ?
                                                    <option value={13}>{translate.t("Actor")}</option>
                                                :null}
                                                {props.showTabsByMaterial.previousLifes ?
                                                    <option value={21}>{translate.t("PreviousLifes")}</option>
                                                :null}
                                                <option value={20}>{translate.t("PersonalNotes")}</option>
                                                {props.showTabsByMaterial.guide ?
                                                    <option value={23}>{translate.t("Guide")}</option>
                                                :null}
                                            </select>
                                        </div>
                                    </div>
                                :null}
                                <TinyMCEEditor 
                                    useWindowHeight = {true}
                                    onChange={(content,type) => setFormData({...formData,text:content})} 
                                    initialValue={formData.text}  
                                />
                            </>
    
                        )
                    }

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => dailyLib.addPost(formData,addCustomerDailyPost)}>{(props.selectedPostID && props.selectedPostID != 0 ? translate.t("Save") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => CloseAddModal()}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>
    );

}

export default withApollo(AddPost);